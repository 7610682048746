<template>
    <div class="teacher-profile">
        <div class="row">
            <div class="col-12">
                <profile />
            </div>
            <div class="col-12">
                <review-lesson />
            </div>
        </div>
    </div>
</template>

<script>
import Profile from  "./profile.vue";
import ReviewLesson from  "./review-lesson.vue";

export default {
    data() {
        return {}
    },
    components: {
        Profile,
        ReviewLesson
    }
}
</script>
