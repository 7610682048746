<template>
    <div class="row">
        <div class="col-12">
            <div class="profile">
                <div class="cimg">
                    <img :src="student && student.profile_image ? student.profile_image : ''" :alt="student.name"/>
                </div>
                <h3 class="name">
                    {{ student && student.name ? student.name : "no nickname" }}
                </h3>
            </div>
        </div>
        <div class="col-12">
            <div class="rate">
                <div class="d-flex justify-content-between ">
                    <div class="star">
                        <star-rating
                            :rating="Number(student && student.avg_rating ? student.avg_rating : 0)"
                            :star-size="22"
                            :show-rating="false"
                            :read-only="true"
                        />
                        {{ _totalRate(student && student.avg_rating ? student.avg_rating : 0) }}
                        ({{ student && student.rate_qty ? student.rate_qty : 0 }})
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="text-intro text-justify text-pre-line" v-if="student && student.introduction">
                {{ student.introduction }}
            </div>
        </div>
        <RegisterModal
            :isVisible="this.isVisible"
            @cancel="actionCancel"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            isVisible: false,
            addFavoriting: false
        }
    },
    async created() {
        if (!this.member) {
            await this.getMMSMember();
        }
        let teacherId = this.$route.params.teacherId;
        const memberId = this.member && this.member.id ? this.member.id : 0;
        await this.fetchStudentProfile({ id: teacherId, member_id: memberId });
    },
    computed: {
        ...mapGetters({
            member: "getMember",
            student: "teacherProfile/getStudentProfile",
        }),
    },
    methods: {
        ...mapActions({
            fetchStudentProfile: "teacherProfile/fetchStudentProfile",
            getMMSMember: "getMMSMember",
        }),
        actionCancel() {
            this.isVisible = false;
        },
        _totalRate(rate) {
            rate = rate.toString().slice(0, 3);
            return rate;
        },
    },
};
</script>

<style scoped>
.bi-heart-fill {
    color: red;
    cursor: pointer;
}
</style>